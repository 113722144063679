<template>
	<div>
		<!-- 圣杯布局 -->
		<Header style="position: fixed;"></Header>
		<div style="height: 60px;"></div>
		<div class="banner"></div>

		<div class="container">
			<div class="left"></div>
			<div class="center">
				<div v-for="item in newsList" :key='item.index' class="vue-flex newContent" @click="goNews(item.index)">
					<div style="margin-right: 60px;">
						<el-image class="animation_son" :src="item.image"></el-image>
					</div>
					<div>
						<div class="newTitle">{{item.title}}</div>
						<div class="newText">{{item.text}}</div>
						<div class="newTime">{{item.data}}</div>
					</div>
				</div>
			</div>
			<div class="right" ></div>
		</div>
		
		<Footer style="margin-top: 30px;"></Footer>
	</div>
</template>

<script>
	import Header from "@/components/header";
	import Footer from "@/components/footer";
	export default {
		data() {
			return {
				newsList: [
					{
						index: 0,
						image: require('@/static/images/news/Rectangle1.jpg'),
						title: '证监会：加大长期资金对股权科创基金的投资力度',
						text: '推动相关行业主管部门完善法律法规，加大以社保基金、保险资金、养老基金等为代表的长期资金对股权科创基金的投资力度。',
						data: '2023-10-26'
					},{
						index: 1,
						image: require('@/static/images/news/Rectangle2.jpg'),
						title: '证监会：支持头部证券公司通过并购重组等方式做优做强 打造一流的投资银行',
						text: '推动证券公司投行、投资、投研协同联动，不断提升服务实体经济、服务注册制改革能力，助力构建为实体企业提供多元化接力式金融服务体系。',
						data: '2023-11-03'
					},{
						index: 2,
						image: require('@/static/images/news/Rectangle3.jpg'),
						title: '广东设立新型储能产业天使投资基金',
						text: '设立广东省新型储能产业天使投资基金，重点投向种子期、初创期、成长期科技型企业，建立早、中期科创和重大产业项目让利机制。用好政策性开发性金融工具、制造业中长期贷款等，支持新型储能项目建设。加快广州期货交易所电力和碳排放权期货品种研究和上市。',
						data: '2023-10-28'
					},{
						index: 3,
						image: require('@/static/images/news/Rectangle4.jpg'),
						title: '《广东省推进能源高质量发展实施方案（2023-2025年）》',
						text: '推动多能源品种协调发展，加强多渠道、多主体能源供给，构建多元化能源保障体系，保障能源供应安全。',
						data: '2023-11-03'
					}
				],
			}
		},
		components: {
			Header,
			Footer,
		},
		mounted() {
		    
		},
		methods: {
			goNews(index) {
				let routeUrl = this.$router.resolve({
					path:'article',// 跳转
					query:{
						id: index+1 // 参数
					}
				});
				window.open(routeUrl.href, "_blank");
			}
		},
	}
</script>

<style scoped>
.container{
	margin: 60px 0;
    display: flex;
    flex-direction: row;
    flex: 1;
}
.center{
    flex: 0 0 1200px;
}
.left{
    flex: 1;
}
.right{
    flex: 1;
}

.banner {	height: 55vh;	display: flex;	align-items: center;	justify-content: center;	background: url("../static/images/news/002.jpg") no-repeat center center;	background-size: cover; }
.newContent{
	margin: 40px 0;
	cursor: pointer;
}

.newTitle{
	font-size: 24px;
	color: #4B4B4B;
	font-weight: Semibold;
	line-height: 180%;
	letter-spacing: 1px;
}

.newText{
	/* line-height: 250%; */
	margin: 8px 0 12px;
	color: #4B4B4B;
}

.newTime{
	color: #B3B3B3;
}

.animation_son {
	width: 200px;
	height: 110px;
}
.newContent .animation_son {
	transition: transform 0.5s ease;
}
.newContent:hover .animation_son {
	transform: scale(1.1);
}
</style>
